<template>
  <div class="info-box-container relative">
    <div
      class="info-box-wrap no-select ghost"
      :class="{ open: open, 'video-box-small': small }"
    >
      <div class="info-box info-box-video">
        <div class="video-box-image-wrap">
          <div class="video-box-image">
            <img :src="data.thumb" />
          </div>
        </div>
        <h4>
          <span>
            {{ data.title }}
          </span>
        </h4>
        <p class="italic grey2">
          {{ data.sub }}
        </p>
      </div>
    </div>
    <div
      class="info-box-wrap corporeal"
      :class="{ open: open, 'video-box-small': small }"
      ref="box"
    >
      <div class="info-box info-box-video">
        <div class="video-box-image-wrap">
          <div
            class="video-box-image parallax-item parallax-relative"
            data-depth="0.8"
          >
            <img :src="data.thumb" :alt="data.title" ref="image" />
          </div>
        </div>
        <h4
          class="video-box-stagger parallax-item parallax-relative"
          data-depth="0.5"
        >
          <span>
            {{ data.title }}
          </span>
        </h4>
        <p class="italic grey2 video-box-stagger">
          {{ data.sub }}
        </p>
        <button
          class="btn btn-round btn-videobox"
          @mouseover="hoverButton(true)"
          @mouseleave="hoverButton(false)"
          @click="openLightbox(videoData)"
        >
          <i class="ai-video-camera"></i>
        </button>
        <div class="frame" ref="frame">
          <div class="line bottom left">
            <div class="line-dynamic"></div>
          </div>
          <div class="line bottom right">
            <div class="line-dynamic"></div>
          </div>
          <div class="line side left">
            <div class="line-dynamic"></div>
          </div>
          <div class="line side right">
            <div class="line-dynamic"></div>
          </div>
          <div class="line top right">
            <div class="line-dynamic"></div>
          </div>
          <div class="line top left">
            <div class="line-dynamic"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { partComponent, lightboxFunctions } from "@/mixins/core.js";

export default {
  name: "VideoBox",
  mixins: [partComponent, lightboxFunctions],
  props: ["data", "small"],
  data() {
    return {
      timelineHover: null,
      open: false,
    };
  },
  computed: {
    videoData() {
      return {
        type: "video",
        item: this.data.src,
        title: this.data.title,
        sub: this.data.sub,
        poster: this.data.image,
      };
    },
  },
  mounted() {
    const vm = this;
    const { box, frame, image } = this.$refs;

    //enter timeline
    const tl = new TimelineMax({
      paused: true,
    });

    tl.from(box, 1, {
      height: 0,
      ease: Power3.easeInOut,
    })
      .add("boxIn")
      .from(
        image,
        1.35,
        {
          scale: 1.2,
          ease: Power2.easeOut,
        },
        0.05
      )
      .staggerFrom(
        ".video-box-stagger",
        0.55,
        {
          autoAlpha: 0,
          y: "+=20",
          ease: Power2.easeInOut,
        },
        0.08,
        "boxIn-=0.6"
      )
      .call(this.toggleOpen, null, this, "-=0.2");

    this.timeline = tl;

    //hover timeline
    const tlHover = new TimelineMax({
      paused: true,
      ease: Power0.noEase,
    });

    tlHover
      .to(".info-box-video .line.bottom .line-dynamic", 0.1, {
        width: "100%",
      })
      .to(".info-box-video .line.side .line-dynamic", 0.2, {
        height: "100%",
      })
      .to(".info-box-video .line.top .line-dynamic", 0.1, {
        width: "100%",
      })
      .to(
        image,
        0.6,
        {
          scale: 1.1,
          filter: "grayscale(.2)",
        },
        0
      );

    this.timelineHover = tlHover;
  },
  methods: {
    toggleOpen() {
      this.open = true;
    },
    hoverButton(play) {
      play ? this.timelineHover.play() : this.timelineHover.reverse();
    },
  },
};
</script>

<style scoped>
.items-shown .info-box-wrap {
  height: auto !important;
}

.video-box-small p {
  display: none;
}
.video-box-small h4 {
  margin-bottom: 2.3em;
}
.video-box-image {
  width: 100%;
  height: 11.688em;
  overflow: hidden;
}
.video-box-image img {
  object-fit: cover;
  width: 102%;
  height: 102%;
  filter: grayscale(0.78);
}
.info-box-wrap {
  overflow: hidden;
  transform-origin: top left;
  padding: 0;
  display: flex !important;
  font-size: 1rem;
  margin: 1rem 0;
}
.info-box {
  width: 21.8em;
  background: rgba(255, 255, 255, 0.65);
  position: relative;
  padding: 1px;
  text-align: center;
}
.btn-round {
  position: absolute;
  top: 8.95rem;
  left: 50%;
  box-shadow: 0 0 0 0 rgba(55, 152, 251, 0.6);
  transform: translate(-50%, 0);
}
.info-box-wrap.open .btn-round {
  animation: pulse 1.25s 2 cubic-bezier(0.66, 0, 0, 1);
}
p {
  font-size: 0.938em;
  margin: 1.66em 0 1.9em;
  z-index: 2;
  position: relative;
}
h4 {
  position: relative;
  padding: 3.7em 0 2.1rem 0;
  margin: 0;
  z-index: 2;
  font-size: 1em;
}
h4 span {
  width: 80%;
  font-size: 1.438em;
  margin: 0 auto;
  display: block;
}
h4::after {
  content: "";
  background: var(--blue);
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 23%;
  height: 3px;
  position: absolute;
  max-width: 64px;
}
.bottom.left,
.bottom.right {
  width: 50%;
}
</style>
